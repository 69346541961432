@charset "UTF-8";

$zaux-color-default-white: #FFFFFF;
$zaux-color-default-black: #333333;
$zaux-color-default-red: #d9534f;
$zaux-color-default-green: #5cb85c;
$zaux-color-default-blue: #337ab7;
$zaux-color-default-magenta: #FF00FF;
$zaux-color-default-orange: #f0ad4e;

$zaux-color-set-default: (
	"white": #FFFFFF,
	"black": #333333,
	"red": #d9534f,
	"green": #5cb85c,
	"blue": #337ab7,
	"magenta": #FF00FF,
	"orange": #f0ad4e,
);

$zaux-color-app-theme: #46505A;

$zaux-color-set-app: (
	"theme": #46505A,
);

$zaux-color-set1-blue900: #2C3235;
$zaux-color-set1-blue700: #1E3444;
$zaux-color-set1-aqua500: #468A7A;
$zaux-color-set1-aqua300: #A2CECC;
$zaux-color-set1-aqua200: #33C0B4;
$zaux-color-set1-grey300: #707070;
$zaux-color-set1-grey500: #61666A;

$zaux-color-set-set1: (
	"blue900": #2C3235,
	"blue700": #1E3444,
	"aqua500": #468A7A,
	"aqua300": #A2CECC,
	"aqua200": #33C0B4,
	"grey300": #707070,
	"grey500": #61666A,
);


/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

////////////////////////////////////////////////////
// Configuration
////////////////////////////////////////////////////
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

////////////////////////////////////////////////////
// Utilities customization
// @see https://getbootstrap.com/docs/5.0/utilities/api/#enable-responsive
// @see node_modules/bootstrap/scss/_utilities.scss
////////////////////////////////////////////////////

////////////////////////////////////////////////////
// Layout & components
////////////////////////////////////////////////////
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

// @import "bootstrap/scss/type";
////////////////////////////////////////////////////
// BEGIN part of "bootstrap/scss/type"
////////////////////////////////////////////////////

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//
.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-font-size);

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y;
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-footer-font-size);
  color: $blockquote-footer-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
////////////////////////////////////////////////////
// END part of "bootstrap/scss/type"
////////////////////////////////////////////////////

// @import "bootstrap/scss/images";
.img-fluid {
  @include img-fluid();
}
@include media-breakpoint-up(lg) {
  .img-lg-fluid {
    @include img-fluid();
  }
}
@include media-breakpoint-up(xl) {
  .img-xl-fluid {
    @include img-fluid();
  }
}

// @import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms/labels";
@import "bootstrap/scss/forms/form-text";
@import "bootstrap/scss/forms/form-control";
@import "bootstrap/scss/forms/form-select";
@import "bootstrap/scss/forms/form-check";
@import "bootstrap/scss/forms/form-range";
@import "bootstrap/scss/forms/floating-labels";
@import "bootstrap/scss/forms/input-group";
@import "bootstrap/scss/forms/validation";
// @import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
// @import "bootstrap/scss/placeholders";

////////////////////////////////////////////////////
// Helpers
////////////////////////////////////////////////////
@import "bootstrap/scss/helpers/clearfix";
// @import "bootstrap/scss/helpers/colored-links";
// @import "bootstrap/scss/helpers/ratio";
@import "bootstrap/scss/helpers/position";
// @import "bootstrap/scss/helpers/stacks";
@import "bootstrap/scss/helpers/visually-hidden";
@import "bootstrap/scss/helpers/stretched-link";
@import "bootstrap/scss/helpers/text-truncation";
@import "bootstrap/scss/helpers/vr";

////////////////////////////////////////////////////
// Utilities
////////////////////////////////////////////////////
@import "bootstrap/scss/utilities/api";

////////////////////////////////////////////////////
// These below are bootstrap's inner bundles, the inclusion of the single
// SCSS files (inside the bundles) are listed above in this file.
////////////////////////////////////////////////////
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/helpers";

////////////////////////////////////////////////////
// These below are bootstrap's inner bundles, the inclusion of the single
// SCSS files (inside the bundles) are listed above in this file.
////////////////////////////////////////////////////

@include onIE() {
  .visually-hidden {
    display: none;
  }
}
